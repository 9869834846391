import React from "react";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const Icons = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: 0, position: "absolute", width: 0 }}
  >
    <defs>
      <symbol id="icon-play" viewBox="0 0 25 32">
        <path d="M0 3.981c0-2.323 2.59-3.709 4.523-2.421l18.028 12.019c1.727 1.151 1.727 3.69 0 4.841l-18.028 12.019c-1.933 1.289-4.523-0.097-4.523-2.421v-24.038z" />
      </symbol>
      <symbol id="icon-line-arrow" viewBox="0 0 64 32">
        <path d="M55.273 13.091c1.607 0 2.909 1.302 2.909 2.909 0 1.534-1.187 2.79-2.692 2.901l-0.217 0.008h-52.364c-1.607 0-2.909-1.302-2.909-2.909 0-1.534 1.187-2.79 2.692-2.901l0.217-0.008h52.364z" />
        <path d="M49.295 0.489c1.27-0.847 2.964-0.563 3.894 0.614l0.14 0.193 9.803 14.705-9.803 14.705c-0.891 1.337-2.697 1.698-4.034 0.807-1.27-0.847-1.659-2.519-0.931-3.83l0.124-0.204 7.65-11.477-7.65-11.477c-0.847-1.27-0.563-2.964 0.614-3.894l0.193-0.14z" />
      </symbol>
      <symbol id="icon-circle-left-arrow" viewBox="0 0 32 32">
        <path d="M16 31.673c8.656 0 15.673-7.017 15.673-15.673s-7.017-15.673-15.673-15.673c-8.656 0-15.673 7.017-15.673 15.673s7.017 15.673 15.673 15.673zM16 29.714c-7.574 0-13.714-6.14-13.714-13.714s6.14-13.714 13.714-13.714c7.574 0 13.714 6.14 13.714 13.714s-6.14 13.714-13.714 13.714z" />
        <path d="M16.263 11.413c0.369-0.396 0.989-0.417 1.385-0.048 0.371 0.346 0.413 0.912 0.112 1.308l-0.065 0.077-2.805 3.005 2.805 3.005c0.346 0.371 0.349 0.939 0.022 1.313l-0.070 0.072c-0.371 0.346-0.939 0.349-1.313 0.022l-0.072-0.070-3.429-3.673c-0.328-0.351-0.35-0.883-0.066-1.259l0.066-0.078 3.429-3.673z" />
      </symbol>
      <symbol id="icon-circle-right-arrow" viewBox="0 0 32 32">
        <path d="M16 0.327c-8.656 0-15.673 7.017-15.673 15.673s7.017 15.673 15.673 15.673c8.656 0 15.673-7.017 15.673-15.673s-7.017-15.673-15.673-15.673zM16 2.286c7.574 0 13.714 6.14 13.714 13.714s-6.14 13.714-13.714 13.714c-7.574 0-13.714-6.14-13.714-13.714s6.14-13.714 13.714-13.714z" />
        <path d="M14.352 11.855c0.371-0.346 0.939-0.349 1.313-0.022l0.072 0.070 3.429 3.673c0.328 0.351 0.35 0.883 0.066 1.259l-0.066 0.078-3.429 3.673c-0.369 0.395-0.989 0.417-1.385 0.048-0.371-0.346-0.413-0.913-0.112-1.308l0.065-0.077 2.805-3.005-2.805-3.005c-0.346-0.371-0.349-0.939-0.022-1.313l0.070-0.072z" />
      </symbol>
      <symbol id="icon-plus" viewBox="0 0 32 32">
        <path d="M16 32c8.837 0 16-7.163 16-16s-7.163-16-16-16c-8.837 0-16 7.163-16 16s7.163 16 16 16z" />
        <path
          fill="#fff"
          style={{ fill: "var(--color2, #fff)" }}
          d="M16.32 8.96c0.499 0 0.909 0.381 0.956 0.868l0.004 0.092v12.8c0 0.53-0.43 0.96-0.96 0.96-0.499 0-0.909-0.381-0.956-0.868l-0.004-0.092v-12.8c0-0.53 0.43-0.96 0.96-0.96z"
        />
        <path
          fill="#fff"
          style={{ fill: "var(--color2, #fff)" }}
          d="M22.72 15.36c0.53 0 0.96 0.43 0.96 0.96 0 0.499-0.381 0.909-0.868 0.956l-0.092 0.004h-12.8c-0.53 0-0.96-0.43-0.96-0.96 0-0.499 0.381-0.909 0.868-0.956l0.092-0.004h12.8z"
        />
      </symbol>
      <symbol id="icon-line-arrow-thick" viewBox="0 0 47 32">
        <path d="M35.692 12.288c2.039 0 3.692 1.653 3.692 3.692 0 1.919-1.464 3.497-3.337 3.675l-0.356 0.017h-32c-2.039 0-3.692-1.653-3.692-3.692 0-1.919 1.464-3.497 3.337-3.675l0.356-0.017h32z" />
        <path d="M29.596 2.162c1.429-1.269 3.57-1.227 4.948 0.039l0.265 0.27 9.819 11.056c1.157 1.303 1.237 3.222 0.236 4.609l-0.231 0.289-9.819 11.103c-1.351 1.528-3.684 1.671-5.212 0.32-1.432-1.266-1.648-3.396-0.556-4.916l0.236-0.295 7.648-8.653-7.643-8.608c-1.269-1.429-1.227-3.57 0.039-4.948l0.27-0.265z" />
      </symbol>
      <symbol id="icon-up-arrow" viewBox="0 0 64 32">
        <path d="M2.591 21.341l29.409-19.606 29.409 19.606c2.674 1.782 3.396 5.395 1.614 8.068-1.693 2.54-5.038 3.319-7.661 1.862l-0.408-0.249-22.954-15.302-22.954 15.302c-2.54 1.693-5.927 1.126-7.788-1.227l-0.281-0.386c-1.693-2.54-1.126-5.927 1.227-7.788l0.386-0.281z" />
      </symbol>
      <symbol id="icon-down-arrow" viewBox="0 0 60 32">
        <path d="M48.471 2.538c2.68-1.949 6.432-1.357 8.381 1.323 1.834 2.522 1.417 5.995-0.872 8.019l-0.451 0.362-22 16c-1.942 1.412-4.52 1.521-6.56 0.326l-0.498-0.326-22-16c-2.68-1.949-3.272-5.702-1.323-8.381 1.834-2.522 5.266-3.196 7.898-1.641l0.483 0.318 18.467 13.43 18.475-13.43z" />
      </symbol>
      <symbol id="icon-instagram" viewBox="0 0 20 20">
        <path d="M10.003 4.865c-2.836 0-5.132 2.298-5.132 5.132 0 2.836 2.298 5.132 5.132 5.132 2.836 0 5.132-2.298 5.132-5.132 0-2.836-2.298-5.132-5.132-5.132zM10.003 13.328c-1.841 0-3.331-1.491-3.331-3.331s1.491-3.331 3.331-3.331c1.84 0 3.331 1.491 3.331 3.331s-1.49 3.331-3.331 3.331z" />
        <path d="M14.123 0.063c-1.84-0.086-6.398-0.082-8.239 0-1.618 0.076-3.046 0.467-4.197 1.617-1.923 1.923-1.677 4.515-1.677 8.316 0 3.89-0.217 6.422 1.677 8.316 1.931 1.93 4.56 1.678 8.316 1.678 3.853 0 5.183 0.002 6.546-0.525 1.852-0.719 3.251-2.375 3.387-5.349 0.087-1.841 0.082-6.398 0-8.239-0.165-3.511-2.049-5.64-5.813-5.813zM17.035 17.040c-1.261 1.261-3.010 1.148-7.057 1.148-4.167 0-5.837 0.062-7.057-1.161-1.404-1.398-1.15-3.642-1.15-7.044 0-4.604-0.473-7.92 4.148-8.157 1.062-0.038 1.374-0.050 4.047-0.050l0.037 0.025c4.441 0 7.925-0.465 8.134 4.155 0.047 1.054 0.058 1.371 0.058 4.039-0.001 4.118 0.078 5.799-1.162 7.044z" />
        <path d="M15.339 5.862c0.662 0 1.199-0.537 1.199-1.199s-0.537-1.199-1.199-1.199c-0.662 0-1.199 0.537-1.199 1.199s0.537 1.199 1.199 1.199z" />
      </symbol>
      <symbol id="icon-facebook" viewBox="0 0 24 24">
        <path d="M23.989 12q0-2.477-0.929-4.644t-2.589-3.828-3.828-2.589-4.644-0.929-4.644 0.929-3.828 2.589-2.589 3.828-0.929 4.644q0 2.195 0.704 4.137t2.026 3.518 3.068 2.645 3.771 1.463v-8.162h-2.364v-3.602h2.364v-2.983q0-1.182 0.591-2.139t1.52-1.52 2.111-0.563h2.983v3.602h-2.364q-0.507 0-0.872 0.366t-0.366 0.816v2.42h3.602v3.602h-3.602v8.33q2.308-0.225 4.278-1.238t3.434-2.617 2.28-3.659 0.816-4.419z" />
      </symbol>
      <symbol id="icon-paper-plane" viewBox="0 0 36 32">
        <path d="M30.717 0.159l-25.988 1.368c-3.302 0.174-4.6 4.366-1.973 6.375l10.791 8.252 3.044 11.569c0.846 3.212 5.242 3.614 6.656 0.609l10.874-23.108c1.145-2.432-0.72-5.206-3.404-5.065zM4.916 5.077l25.988-1.368-10.874 23.108-3.044-11.569c-0.201-0.764-0.651-1.44-1.279-1.92l-10.791-8.252z" />
        <path d="M30.98 1.21l2.039 2.913-17.778 12.444-2.039-2.913z" />
      </symbol>
      <symbol id="icon-minus" viewBox="0 0 149 32">
        <path d="M125.462 0c8.837 0 16 7.163 16 16 0 8.317-6.345 15.151-14.459 15.927l-1.541 0.073h-101.587c-8.837 0-16-7.163-16-16 0-8.317 6.345-15.151 14.459-15.927l1.541-0.073z" />
      </symbol>
      <symbol id="icon-tick" viewBox="0 0 44 32">
        <path d="M36.822 2.265c1.468-1.415 3.805-1.373 5.221 0.095 1.327 1.376 1.373 3.517 0.164 4.945l-0.259 0.276-22.974 22.154c-1.341 1.293-3.416 1.374-4.848 0.242l-0.278-0.242-11.487-11.077c-1.468-1.415-1.51-3.753-0.095-5.221 1.327-1.376 3.464-1.5 4.936-0.343l0.285 0.248 8.923 8.605z" />
      </symbol>
      <symbol id="icon-basket" viewBox="0 0 41 32">
        <path d="M35.288 7.619h-29.433c-2.974 0-5.156 2.795-4.435 5.68l3.81 15.238c0.509 2.035 2.337 3.463 4.435 3.463h21.814c2.098 0 3.926-1.428 4.435-3.463l3.81-15.238c0.721-2.885-1.461-5.68-4.435-5.68zM31.478 27.429h-21.814l-3.81-15.238h29.433z" />
        <path d="M25.905 0h-10.667c-2.525 0-4.571 2.047-4.571 4.571v7.619h19.81v-7.619c0-2.525-2.047-4.571-4.571-4.571zM15.238 4.571h10.667v3.048h-10.667z" />
      </symbol>
      <symbol id="icon-arrow-down" viewBox="0 0 60 32">
        <path d="M48.342 3.244c2.627-2.020 6.394-1.529 8.414 1.097 1.902 2.472 1.578 5.954-0.656 8.040l-0.441 0.374-22 16.923c-1.991 1.531-4.703 1.649-6.805 0.353l-0.512-0.353-22-16.923c-2.627-2.020-3.118-5.787-1.097-8.414 1.902-2.472 5.35-3.053 7.939-1.428l0.475 0.331 18.342 14.108z" />
      </symbol>
      <symbol id="icon-plus-alt" viewBox="0 0 32 32">
        <path d="M16.001 1.625c1.663 0 3.030 1.269 3.185 2.892l0.015 0.308v22.349c0 1.767-1.433 3.2-3.2 3.2-1.663 0-3.030-1.269-3.185-2.892l-0.015-0.308v-22.349c0-1.767 1.433-3.2 3.2-3.2z" />
        <path d="M27.175 12.8c1.767 0 3.2 1.433 3.2 3.2 0 1.663-1.269 3.030-2.892 3.185l-0.308 0.015h-22.349c-1.767 0-3.2-1.433-3.2-3.2 0-1.663 1.269-3.030 2.892-3.185l0.308-0.015z" />
      </symbol>
      <symbol id="icon-ideal-room-size" viewBox="0 0 32 32">
        <path opacity="0.2" d="M15.020 30.694v-15.664l15.020-6.54v15.080z" />
        <path d="M15.196 0.13l-14.458 6.982c-0.451 0.218-0.738 0.675-0.738 1.176v15.425c0 0.501 0.287 0.958 0.738 1.176l14.458 6.982c0.36 0.174 0.779 0.173 1.139-0.001l14.375-6.982c0.45-0.218 0.736-0.675 0.736-1.175v-15.403c0-0.5-0.285-0.955-0.734-1.174l-14.375-7.003c-0.36-0.175-0.78-0.176-1.14-0.002zM15.762 2.757l13.071 6.368v13.769l-13.070 6.348-13.15-6.35v-13.785l13.149-6.35z" />
        <path d="M28.456 7.341c0.648-0.317 1.43-0.049 1.747 0.599 0.302 0.617 0.073 1.356-0.509 1.699l-0.090 0.048-13.596 6.654c-0.32 0.157-0.689 0.175-1.021 0.054l-0.109-0.046-14.129-6.654c-0.653-0.307-0.932-1.086-0.625-1.738 0.293-0.622 1.012-0.905 1.644-0.665l0.094 0.040 13.561 6.387 13.033-6.378z" />
        <path d="M16.474 19.050v10.535h-2.612v-10.535z" />
        <path d="M14.385 16.341c0.433-0.325 1.019-0.347 1.472-0.065l0.095 0.065 2.218 1.663c0.577 0.433 0.694 1.252 0.261 1.829-0.411 0.548-1.171 0.681-1.74 0.322l-0.088-0.061-1.435-1.076-1.434 1.076c-0.548 0.411-1.315 0.326-1.761-0.178l-0.068-0.083c-0.411-0.548-0.326-1.314 0.178-1.761l0.083-0.068 2.218-1.663z" />
      </symbol>
      <symbol id="icon-carbon-filter" viewBox="0 0 33 32">
        <path
          opacity="0.2"
          d="M14.939 5.004l4.187-2.428 4.187 2.428v5.45l-4.187 2.428-4.187-2.428v-5.45z"
        />
        <path
          opacity="0.2"
          d="M23.313 5.004l4.224-2.428 4.224 2.428v5.45l-4.224 2.428-4.224-2.428v-5.45z"
        />
        <path d="M18.602 1.674c0.324-0.188 0.723-0.188 1.047 0l3.682 2.135 3.995-2.151c0.309-0.166 0.681-0.166 0.989 0l3.132 2.428c0.338 0.182 0.549 0.535 0.549 0.919v5.45c0 0.384-0.211 0.737-0.549 0.919l-2.583 2.005v4.982c0.032 0.013 0.064 0.029 0.095 0.045l2.524 1.328c0.338 0.182 0.549 0.535 0.549 0.919l0.090 7.141c0 0.384-0.211 0.737-0.549 0.919l-2.614 1.837c-0.309 0.166-0.681 0.166-0.989 0l-3.995-2.151-3.682 2.135c-0.324 0.188-0.723 0.188-1.047 0l-3.642-2.112-3.331 2.093c-0.339 0.213-0.771 0.213-1.11 0l-3.35-2.105-3.974 2.14c-0.309 0.166-0.681 0.166-0.989 0l0.495-2.104 3.465-1.866v-4.204l-0.22-0.119c-0.078-0.023-0.154-0.056-0.226-0.098l-0.583-0.338-2.436-1.312-0.027-0.117-1.14-0.661c-0.322-0.187-0.52-0.531-0.52-0.903v-5.45c0-0.372 0.198-0.716 0.52-0.903l0.394-0.229 0.129-0.549 3.465-1.866v-4.204l-3.465-1.866-0.495-2.104c0.309-0.166 0.681-0.166 0.989 0l3.974 2.14 3.35-2.105c0.339-0.213 0.771-0.213 1.11 0l3.331 2.093 3.642-2.112zM13.895 9.878v-4.297l-2.821-1.772-2.821 1.772v4.297l2.821 1.772 2.821-1.772zM19.126 11.676l3.143-1.823v-4.248l-3.143-1.823-3.143 1.823v4.248l3.143 1.823zM16.627 26.601l3.143 1.823 3.143-1.823v-4.248l-3.143-1.823-3.143 1.823v4.248zM14.54 22.328l-2.821-1.772-2.821 1.772v4.297l2.821 1.772 2.821-1.772v-4.297zM10.031 18.227v-4.248l-3.143-1.823-3.143 1.823v4.248l3.055 1.772 0.091 0.049 3.14-1.821zM25 26.578l3.465 1.866 3.465-1.866v-4.204l-3.465-1.866-3.465 1.866v4.204zM18.404 13.979l-3.143-1.823-3.143 1.823v4.248l3.143 1.823 3.143-1.823v-4.248zM20.491 18.227l3.143 1.823 3.143-1.823v-4.248l-3.143-1.823-3.143 1.823v4.248zM24.356 5.628v4.204l3.465 1.866 3.465-1.866v-4.204l-3.465-1.866-3.465 1.866z" />
        <path d="M28.681 2.783c0.384 0 0.696 0.311 0.696 0.696v24.605c0 0.384-0.311 0.696-0.696 0.696h-24.605c-0.384 0-0.696-0.311-0.696-0.696v-24.605c0-0.384 0.311-0.696 0.696-0.696h24.605zM4.075 0c-1.921 0-3.478 1.557-3.478 3.478v24.605c0 1.921 1.557 3.478 3.478 3.478h24.605c1.921 0 3.478-1.557 3.478-3.478v-24.605c0-1.921-1.557-3.478-3.478-3.478h-24.605z" />
      </symbol>
      <symbol id="icon-filter-coating" viewBox="0 0 32 32">
        <path
          fill="#fff"
          d="M29.195 22.717l-12.113-5.793-13.693 5.793 13.693 6.997z"
        />
        <path d="M15.436 14.781c0.345-0.171 0.744-0.188 1.1-0.050l0.117 0.052 14.597 7.319c0.672 0.337 0.944 1.155 0.607 1.828-0.321 0.64-1.079 0.917-1.731 0.651l-0.097-0.044-13.989-7.015-14.071 6.994c-0.641 0.319-1.413 0.085-1.774-0.52l-0.051-0.093c-0.319-0.641-0.085-1.413 0.52-1.774l0.093-0.051 14.68-7.297z" />
        <path d="M30.020 22.106c0.67-0.342 1.49-0.075 1.832 0.595 0.325 0.638 0.099 1.412-0.502 1.78l-0.093 0.052-14.357 7.319c-0.344 0.175-0.744 0.196-1.101 0.060l-0.117-0.051-14.92-7.319c-0.675-0.331-0.954-1.147-0.623-1.822 0.315-0.643 1.070-0.927 1.725-0.666l0.097 0.043 14.308 7.019 13.75-7.010z" />
        <path
          fill="#fff"
          d="M29.195 15.202l-12.113-5.793-13.693 5.793 13.693 6.997z"
        />
        <path d="M15.436 7.461c0.345-0.171 0.744-0.188 1.1-0.050l0.117 0.052 14.597 7.319c0.672 0.337 0.944 1.155 0.607 1.828-0.321 0.64-1.079 0.917-1.731 0.651l-0.097-0.044-13.989-7.015-14.071 6.994c-0.641 0.319-1.413 0.085-1.774-0.52l-0.051-0.093c-0.319-0.641-0.085-1.413 0.52-1.774l0.093-0.051 14.68-7.297z" />
        <path fill="#fff" d="M1.362 16l14.92 7.319 14.357-7.319z" />
        <path d="M30.020 14.787c0.67-0.342 1.49-0.075 1.832 0.595 0.325 0.638 0.099 1.412-0.502 1.78l-0.093 0.052-14.357 7.319c-0.344 0.175-0.744 0.196-1.101 0.060l-0.117-0.051-14.92-7.319c-0.675-0.331-0.954-1.147-0.623-1.822 0.315-0.643 1.070-0.927 1.725-0.666l0.097 0.043 14.308 7.019 13.75-7.010z" />
        <path
          opacity="0.2"
          d="M28.463 8.534l-12.113-5.793-13.693 5.793 13.693 6.997z"
        />
        <path d="M15.436 0.142c0.345-0.171 0.744-0.188 1.1-0.050l0.117 0.052 14.597 7.319c0.672 0.337 0.944 1.155 0.607 1.828-0.321 0.64-1.079 0.917-1.731 0.651l-0.097-0.044-13.989-7.015-14.071 6.994c-0.641 0.319-1.413 0.085-1.774-0.52l-0.051-0.093c-0.319-0.641-0.085-1.413 0.52-1.774l0.093-0.051 14.68-7.297z" />
        <path d="M30.020 7.468c0.67-0.342 1.49-0.075 1.832 0.595 0.325 0.638 0.099 1.412-0.502 1.78l-0.093 0.052-14.357 7.319c-0.344 0.175-0.744 0.196-1.101 0.060l-0.117-0.051-14.92-7.319c-0.675-0.331-0.954-1.147-0.623-1.822 0.315-0.643 1.070-0.927 1.725-0.666l0.097 0.043 14.308 7.019 13.75-7.010z" />
      </symbol>
      <symbol id="icon-hepa-filter" viewBox="0 0 32 32">
        <path
          opacity="0.2"
          d="M7.184 1.158c0.767 0 1.395 0.593 1.451 1.346l0.004 0.109v26.776c0 0.803-0.651 1.455-1.455 1.455-0.767 0-1.395-0.593-1.451-1.346l-0.004-0.109v-26.776c0-0.803 0.651-1.455 1.455-1.455z"
        />
        <path
          opacity="0.2"
          d="M18.939 1.158c0.767 0 1.395 0.593 1.451 1.346l0.004 0.109v26.776c0 0.803-0.651 1.455-1.455 1.455-0.767 0-1.395-0.593-1.451-1.346l-0.004-0.109v-26.776c0-0.803 0.651-1.455 1.455-1.455z"
        />
        <path d="M29.388 23.362c0.803 0 1.455 0.651 1.455 1.455 0 0.767-0.593 1.395-1.346 1.451l-0.109 0.004h-27.429c-0.803 0-1.455-0.651-1.455-1.455 0-0.767 0.593-1.395 1.346-1.451l0.109-0.004h27.429z" />
        <path d="M30.041 5.729c0.803 0 1.455 0.651 1.455 1.455 0 0.767-0.593 1.395-1.346 1.451l-0.109 0.004h-27.429c-0.803 0-1.455-0.651-1.455-1.455 0-0.767 0.593-1.395 1.346-1.451l0.109-0.004h27.429z" />
        <path d="M29.388 11.607c0.803 0 1.455 0.651 1.455 1.455 0 0.767-0.593 1.395-1.346 1.451l-0.109 0.004h-27.429c-0.803 0-1.455-0.651-1.455-1.455 0-0.767 0.593-1.395 1.346-1.451l0.109-0.004h27.429z" />
        <path d="M30.041 17.484c0.803 0 1.455 0.651 1.455 1.455 0 0.767-0.593 1.395-1.346 1.451l-0.109 0.004h-27.429c-0.803 0-1.455-0.651-1.455-1.455 0-0.767 0.593-1.395 1.346-1.451l0.109-0.004h27.429z" />
        <path
          opacity="0.2"
          d="M13.061 1.158c0.767 0 1.395 0.593 1.451 1.346l0.004 0.109v26.776c0 0.803-0.651 1.455-1.455 1.455-0.767 0-1.395-0.593-1.451-1.346l-0.004-0.109v-26.776c0-0.803 0.651-1.455 1.455-1.455z"
        />
        <path
          opacity="0.2"
          d="M24.816 1.158c0.767 0 1.395 0.593 1.451 1.346l0.004 0.109v26.776c0 0.803-0.651 1.455-1.455 1.455-0.767 0-1.395-0.593-1.451-1.346l-0.004-0.109v-26.776c0-0.803 0.651-1.455 1.455-1.455z"
        />
        <path d="M28.364 0h-24.727c-2.008 0-3.636 1.628-3.636 3.636v24.727c0 2.008 1.628 3.636 3.636 3.636h24.727c2.008 0 3.636-1.628 3.636-3.636v-24.727c0-2.008-1.628-3.636-3.636-3.636zM3.636 2.909h24.727c0.402 0 0.727 0.326 0.727 0.727v24.727c0 0.402-0.326 0.727-0.727 0.727h-24.727c-0.402 0-0.727-0.326-0.727-0.727v-24.727c0-0.402 0.326-0.727 0.727-0.727z" />
      </symbol>
      <symbol id="icon-down-arrow-thin" viewBox="0 0 60 32">
        <path d="M51.514 2.307c2.335-1.796 5.683-1.359 7.479 0.976 1.727 2.245 1.389 5.427-0.715 7.265l-0.261 0.214-24.649 18.96c-1.821 1.401-4.321 1.471-6.211 0.21l-0.293-0.21-24.649-18.96c-2.335-1.796-2.771-5.144-0.976-7.479 1.727-2.245 4.889-2.735 7.205-1.173l0.274 0.197 21.395 16.459 21.398-16.459z" />
      </symbol>
      <symbol id="icon-info" viewBox="0 0 32 32">
        <path d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16c8.837 0 16-7.163 16-16s-7.163-16-16-16zM16 2.909c7.23 0 13.091 5.861 13.091 13.091s-5.861 13.091-13.091 13.091c-7.23 0-13.091-5.861-13.091-13.091s5.861-13.091 13.091-13.091z" />
        <path d="M18.804 20.619l-0.186 0.762c-0.559 0.22-1.004 0.387-1.337 0.499-0.333 0.119-0.719 0.178-1.159 0.178-0.677 0-1.205-0.164-1.583-0.491-0.372-0.333-0.559-0.753-0.559-1.261 0-0.198 0.014-0.398 0.042-0.601 0.028-0.209 0.073-0.443 0.135-0.702l0.694-2.471c0.062-0.237 0.113-0.46 0.152-0.668 0.045-0.215 0.068-0.409 0.068-0.584 0-0.316-0.065-0.536-0.195-0.66s-0.378-0.186-0.745-0.186c-0.181 0-0.367 0.028-0.559 0.085-0.186 0.056-0.347 0.11-0.482 0.161l0.186-0.762c0.457-0.186 0.894-0.344 1.312-0.474 0.418-0.135 0.813-0.203 1.185-0.203 0.671 0 1.188 0.164 1.549 0.491 0.367 0.322 0.55 0.742 0.55 1.261 0 0.107-0.014 0.296-0.042 0.567-0.023 0.271-0.068 0.519-0.135 0.745l-0.694 2.463c-0.056 0.197-0.107 0.423-0.152 0.677s-0.068 0.446-0.068 0.575c0 0.327 0.073 0.55 0.22 0.669s0.401 0.178 0.762 0.178c0.169 0 0.361-0.028 0.576-0.085 0.214-0.062 0.37-0.116 0.465-0.161zM18.982 10.285c0 0.429-0.164 0.796-0.491 1.1-0.322 0.299-0.711 0.449-1.168 0.449s-0.849-0.15-1.176-0.449c-0.327-0.305-0.491-0.671-0.491-1.1s0.164-0.796 0.491-1.1c0.327-0.305 0.719-0.457 1.176-0.457s0.846 0.152 1.168 0.457c0.327 0.305 0.491 0.671 0.491 1.1z" />
      </symbol>
      <symbol id="icon-info-fill" viewBox="0 0 32 32">
        <path
          fill="#c7c7c7"
          style={{ fill: "var(--color1, #c7c7c7)" }}
          d="M32 16c0 8.837-7.163 16-16 16s-16-7.163-16-16c0-8.836 7.163-16 16-16s16 7.163 16 16z"
        />
        <path
          fill="#c7c7c7"
          style={{ fill: "var(--color1, #c7c7c7)" }}
          d="M16 29.091c-7.23 0-13.091-5.861-13.091-13.091s5.861-13.091 13.091-13.091c7.23 0 13.091 5.861 13.091 13.091s-5.861 13.091-13.091 13.091zM16 32c8.837 0 16-7.163 16-16s-7.163-16-16-16c-8.837 0-16 7.163-16 16s7.163 16 16 16z"
        />
        <path
          fill="#fff"
          style={{ fill: "var(--color2, #fff)" }}
          d="M18.804 20.619l-0.186 0.762c-0.559 0.22-1.004 0.386-1.337 0.499-0.333 0.118-0.719 0.178-1.159 0.178-0.677 0-1.205-0.164-1.583-0.491-0.372-0.333-0.559-0.753-0.559-1.261 0-0.198 0.014-0.398 0.042-0.601 0.028-0.209 0.073-0.443 0.135-0.703l0.694-2.471c0.062-0.237 0.113-0.46 0.152-0.669 0.045-0.214 0.068-0.409 0.068-0.584 0-0.316-0.065-0.536-0.195-0.66s-0.378-0.186-0.745-0.186c-0.181 0-0.367 0.028-0.559 0.085-0.186 0.056-0.347 0.11-0.482 0.161l0.186-0.762c0.457-0.186 0.894-0.344 1.312-0.474 0.418-0.135 0.813-0.203 1.185-0.203 0.671 0 1.188 0.164 1.549 0.491 0.367 0.322 0.55 0.742 0.55 1.261 0 0.107-0.014 0.296-0.042 0.567-0.023 0.271-0.068 0.519-0.135 0.745l-0.694 2.463c-0.056 0.198-0.107 0.423-0.152 0.677s-0.068 0.446-0.068 0.576c0 0.327 0.073 0.55 0.22 0.669s0.401 0.178 0.762 0.178c0.169 0 0.361-0.028 0.576-0.084 0.214-0.062 0.37-0.116 0.465-0.161zM18.982 10.285c0 0.429-0.164 0.796-0.491 1.1-0.322 0.299-0.711 0.449-1.168 0.449s-0.849-0.15-1.176-0.449c-0.327-0.305-0.491-0.671-0.491-1.1s0.164-0.796 0.491-1.1c0.327-0.305 0.719-0.457 1.176-0.457s0.846 0.152 1.168 0.457c0.327 0.305 0.491 0.671 0.491 1.1z"
        />
      </symbol>
      <symbol id="icon-left-arrow" viewBox="0 0 14 24">
        <path d="M9.845 1.562c0.904-0.969 2.423-1.021 3.392-0.117 0.908 0.848 1.011 2.236 0.275 3.204l-0.158 0.188-6.873 7.361 6.873 7.364c0.848 0.908 0.855 2.3 0.054 3.216l-0.171 0.176c-0.908 0.848-2.3 0.855-3.216 0.054l-0.176-0.171-8.4-9c-0.803-0.861-0.857-2.164-0.161-3.084l0.161-0.191 8.4-9z" />
      </symbol>
      <symbol id="icon-link-arrow-thick" viewBox="0 0 142 80">
        <path d="M0 38.246c0-4.532 3.674-8.205 8.205-8.205h107.752c4.532 0 8.205 3.674 8.205 8.205s-3.673 8.205-8.205 8.205h-107.752c-4.532 0-8.205-3.674-8.205-8.205z" />
        <path d="M104.090 75.008c-3.77-2.514-4.79-7.608-2.276-11.378l15.59-23.385-15.59-23.385c-2.514-3.77-1.495-8.865 2.276-11.378s8.865-1.495 11.378 2.276l21.658 32.488-21.658 32.488c-2.514 3.77-7.608 4.789-11.378 2.276z" />
      </symbol>
      <symbol id="icon-arrow-dropdown" viewBox="0 0 53 32">
        <path d="M41.694 1.434c2.153-2.010 5.528-1.893 7.538 0.26 1.855 1.988 1.899 5.016 0.201 7.051l-0.461 0.487-20 18.667c-1.878 1.753-4.707 1.899-6.744 0.438l-0.535-0.438-20-18.667c-2.153-2.010-2.27-5.385-0.26-7.538 1.855-1.988 4.874-2.24 7.021-0.686l0.517 0.426 16.361 15.264 16.361-15.264z" />
      </symbol>
      <symbol id="icon-close-alt" viewBox="0 0 32 32">
        <path d="M32 16c0 8.837-7.163 16-16 16s-16-7.163-16-16c0-8.837 7.163-16 16-16s16 7.163 16 16z" />
        <path
          fill="#fff"
          d="M9.153 9.517c0.524-0.524 1.349-0.565 1.92-0.121l0.137 0.121 10.909 10.909c0.568 0.568 0.568 1.489 0 2.057-0.524 0.524-1.349 0.565-1.92 0.121l-0.137-0.121-10.909-10.909c-0.568-0.568-0.568-1.489 0-2.057z"
        />
        <path
          fill="#fff"
          d="M20.062 9.517c0.568-0.568 1.489-0.568 2.057 0 0.524 0.524 0.565 1.349 0.121 1.92l-0.121 0.137-10.909 10.909c-0.568 0.568-1.489 0.568-2.057 0-0.524-0.524-0.565-1.349-0.121-1.92l0.121-0.137 10.909-10.909z"
        />
      </symbol>
      <symbol id="icon-envelope" viewBox="0 0 41 32">
        <path d="M35.692 0.615h-30.769c-2.379 0-4.308 1.929-4.308 4.308v22.304c0 2.379 1.929 4.308 4.308 4.308h30.769c2.379 0 4.308-1.929 4.308-4.308v-22.304c0-2.379-1.929-4.308-4.308-4.308zM4.923 4.308h30.769c0.34 0 0.615 0.276 0.615 0.615v22.304c0 0.34-0.276 0.615-0.615 0.615h-30.769c-0.34 0-0.615-0.276-0.615-0.615v-22.304c0-0.34 0.276-0.615 0.615-0.615z" />
        <path d="M35.435 2.888c0.784-0.652 1.948-0.545 2.6 0.239 0.611 0.735 0.555 1.804-0.1 2.472l-0.139 0.128-16.281 13.538c-0.647 0.538-1.569 0.566-2.244 0.089l-0.141-0.11-15.719-13.538c-0.773-0.665-0.859-1.831-0.194-2.604 0.624-0.724 1.687-0.846 2.454-0.31l0.149 0.116 14.534 12.518 15.081-12.538z" />
        <path d="M11.517 16.649c0.661-0.776 1.826-0.87 2.602-0.209 0.728 0.62 0.855 1.683 0.324 2.453l-0.115 0.15-9.231 10.841c-0.661 0.776-1.826 0.87-2.603 0.209-0.728-0.62-0.855-1.683-0.324-2.453l0.115-0.15 9.231-10.841z" />
        <path d="M26.444 16.487c0.704-0.647 1.774-0.644 2.474-0.022l0.135 0.133 9.961 10.841c0.69 0.751 0.64 1.919-0.11 2.609-0.704 0.647-1.774 0.644-2.474 0.022l-0.135-0.133-9.961-10.841c-0.69-0.751-0.64-1.919 0.11-2.609z" />
      </symbol>
      <symbol id="icon-down-arrow-alt" viewBox="0 0 52 32">
        <path d="M1.704 1.704c2.153-2.153 5.572-2.266 7.858-0.34l0.37 0.34 16.25 16.248 16.25-16.248c2.153-2.153 5.572-2.266 7.858-0.34l0.37 0.34c2.153 2.153 2.266 5.572 0.34 7.858l-0.34 0.37-20.364 20.364c-2.153 2.153-5.572 2.266-7.858 0.34l-0.37-0.34-20.364-20.364c-2.272-2.272-2.272-5.956 0-8.228z" />
      </symbol>
      <symbol id="icon-filter" viewBox="0 0 47 32">
        <path d="M0 0h46.933v6.4h-46.933v-6.4z" />
        <path d="M8.533 12.8h29.867v6.4h-29.867v-6.4z" />
        <path d="M14.933 25.6h17.067v6.4h-17.067v-6.4z" />
      </symbol>
      <symbol id="icon-stars" viewBox="0 0 212 32">
        <path d="M26.909 30.915c-0.152-0-0.302-0.035-0.436-0.102l-9.36-4.595-9.36 4.595c-0.155 0.077-0.331 0.111-0.506 0.099s-0.344-0.069-0.486-0.166c-0.142-0.096-0.252-0.228-0.318-0.381s-0.085-0.319-0.055-0.48l1.79-9.717-7.574-6.91c-0.125-0.114-0.214-0.259-0.255-0.418s-0.035-0.326 0.019-0.481c0.054-0.156 0.154-0.294 0.288-0.399s0.297-0.174 0.47-0.197l10.466-1.405 4.678-8.874c0.078-0.147 0.198-0.27 0.347-0.356s0.321-0.132 0.496-0.132c0.175 0 0.347 0.046 0.496 0.132s0.269 0.21 0.347 0.356l4.682 8.85 10.466 1.405c0.173 0.024 0.336 0.092 0.47 0.197s0.234 0.244 0.288 0.399c0.054 0.156 0.061 0.322 0.019 0.481s-0.13 0.304-0.255 0.419l-7.578 6.935 1.786 9.735c0.023 0.126 0.016 0.255-0.020 0.378s-0.1 0.238-0.188 0.335c-0.088 0.098-0.198 0.176-0.322 0.23s-0.259 0.082-0.395 0.082v-0.014z" />
        <path d="M71.386 30.915c-0.152-0-0.302-0.035-0.436-0.102l-9.36-4.595-9.36 4.595c-0.155 0.077-0.331 0.111-0.506 0.099s-0.344-0.069-0.486-0.166c-0.142-0.096-0.252-0.228-0.318-0.381s-0.085-0.319-0.055-0.48l1.79-9.717-7.574-6.91c-0.125-0.114-0.214-0.259-0.255-0.418s-0.035-0.326 0.019-0.481c0.054-0.156 0.154-0.294 0.288-0.399s0.297-0.174 0.47-0.197l10.466-1.405 4.678-8.874c0.078-0.147 0.198-0.27 0.347-0.356s0.321-0.132 0.496-0.132c0.175 0 0.347 0.046 0.496 0.132s0.269 0.21 0.347 0.356l4.682 8.85 10.466 1.405c0.174 0.024 0.336 0.092 0.47 0.197s0.234 0.244 0.288 0.399c0.054 0.156 0.061 0.322 0.019 0.481s-0.13 0.304-0.255 0.419l-7.578 6.935 1.786 9.735c0.023 0.126 0.016 0.255-0.020 0.378s-0.1 0.238-0.188 0.335c-0.088 0.098-0.198 0.176-0.322 0.23s-0.259 0.082-0.395 0.082v-0.014z" />
        <path d="M115.863 30.915c-0.152-0-0.302-0.035-0.436-0.102l-9.36-4.595-9.36 4.595c-0.155 0.077-0.331 0.111-0.506 0.099s-0.344-0.069-0.486-0.166c-0.142-0.096-0.252-0.228-0.318-0.381s-0.085-0.319-0.055-0.48l1.79-9.717-7.574-6.91c-0.125-0.114-0.214-0.259-0.255-0.418s-0.035-0.326 0.019-0.481c0.054-0.156 0.154-0.294 0.288-0.399s0.297-0.174 0.47-0.197l10.466-1.405 4.678-8.874c0.078-0.147 0.198-0.27 0.347-0.356s0.321-0.132 0.496-0.132c0.175 0 0.347 0.046 0.496 0.132s0.269 0.21 0.347 0.356l4.682 8.85 10.466 1.405c0.173 0.024 0.335 0.092 0.471 0.197 0.134 0.105 0.233 0.244 0.288 0.399s0.060 0.322 0.019 0.481c-0.041 0.159-0.13 0.304-0.255 0.419l-7.578 6.935 1.786 9.735c0.023 0.126 0.016 0.255-0.020 0.378s-0.1 0.238-0.188 0.335c-0.088 0.098-0.198 0.176-0.322 0.23s-0.259 0.082-0.395 0.082v-0.014z" />
        <path d="M160.34 30.915c-0.152-0-0.302-0.035-0.436-0.102l-9.361-4.595-9.361 4.595c-0.155 0.077-0.331 0.111-0.506 0.099s-0.344-0.069-0.486-0.166c-0.142-0.096-0.252-0.228-0.318-0.381-0.066-0.152-0.085-0.319-0.056-0.48l1.791-9.717-7.575-6.91c-0.124-0.114-0.213-0.259-0.255-0.418s-0.036-0.326 0.019-0.481c0.055-0.156 0.154-0.294 0.288-0.399s0.297-0.174 0.471-0.197l10.466-1.405 4.678-8.874c0.078-0.147 0.198-0.27 0.347-0.356 0.148-0.086 0.32-0.132 0.495-0.132s0.347 0.046 0.495 0.132c0.149 0.086 0.269 0.21 0.347 0.356l4.681 8.85 10.466 1.405c0.173 0.024 0.337 0.092 0.471 0.197s0.233 0.244 0.288 0.399 0.062 0.322 0.020 0.481c-0.043 0.159-0.13 0.304-0.256 0.419l-7.578 6.935 1.787 9.735c0.023 0.126 0.015 0.255-0.020 0.378s-0.1 0.238-0.188 0.335c-0.088 0.098-0.198 0.176-0.321 0.23-0.124 0.054-0.26 0.082-0.396 0.082v-0.014z" />
        <path d="M204.817 30.915c-0.152-0-0.301-0.035-0.436-0.102l-9.361-4.595-9.359 4.595c-0.156 0.077-0.332 0.111-0.506 0.099-0.175-0.012-0.344-0.069-0.486-0.166s-0.252-0.228-0.319-0.381c-0.066-0.152-0.085-0.319-0.055-0.48l1.79-9.717-7.573-6.91c-0.126-0.114-0.215-0.259-0.256-0.418s-0.034-0.326 0.020-0.481c0.053-0.156 0.154-0.294 0.288-0.399s0.296-0.174 0.471-0.197l10.465-1.405 4.679-8.874c0.077-0.147 0.198-0.27 0.346-0.356 0.149-0.086 0.321-0.132 0.495-0.132 0.175 0 0.347 0.046 0.497 0.132 0.148 0.086 0.269 0.21 0.346 0.356l4.683 8.85 10.465 1.405c0.174 0.024 0.337 0.092 0.471 0.197s0.235 0.244 0.288 0.399c0.055 0.156 0.062 0.322 0.020 0.481s-0.13 0.304-0.256 0.419l-7.577 6.935 1.786 9.735c0.023 0.126 0.017 0.255-0.020 0.378-0.036 0.123-0.1 0.238-0.187 0.335-0.089 0.098-0.198 0.176-0.322 0.23-0.123 0.054-0.258 0.082-0.396 0.082v-0.014z" />
      </symbol>
      <symbol id="icon-play" viewBox="0 0 32 32">
        <path d="M8 4.983c0-2.13 2.374-3.4 4.146-2.219l16.526 11.017c1.583 1.055 1.583 3.382 0 4.438l-16.526 11.017c-1.772 1.181-4.146-0.089-4.146-2.219v-22.035z" />
      </symbol>
      <symbol id="icon-cross" viewBox="0 0 32 32">
        <path d="M31.053 5.465c1.249-1.249 1.249-3.278 0-4.528s-3.279-1.249-4.528 0l-10.525 10.535-10.535-10.525c-1.249-1.249-3.278-1.249-4.528 0s-1.249 3.278 0 4.528l10.535 10.525-10.525 10.535c-1.249 1.249-1.249 3.279 0 4.528s3.278 1.249 4.528 0l10.525-10.535 10.535 10.525c1.249 1.249 3.279 1.249 4.528 0s1.249-3.279 0-4.528l-10.535-10.525 10.525-10.535z" />
      </symbol>
      <symbol id="icon-warranty" viewBox="0 0 33 32">
        <path
          fill="none"
          stroke="#565656"
          strokeLinejoin="miter"
          strokeLinecap="butt"
          strokeMiterlimit="4"
          strokeWidth="2.9767"
          d="M14.828 2.172c0.858-0.803 2.193-0.803 3.051 0l2.415 2.261c0.395 0.37 0.911 0.583 1.452 0.601l3.306 0.109c1.175 0.039 2.119 0.982 2.157 2.157l0.109 3.306c0.018 0.541 0.232 1.057 0.601 1.452l2.261 2.415c0.803 0.858 0.803 2.193 0 3.051l-2.261 2.415c-0.37 0.395-0.583 0.911-0.601 1.452l-0.109 3.306c-0.039 1.175-0.982 2.119-2.157 2.157l-3.306 0.109c-0.541 0.018-1.057 0.232-1.452 0.601l-2.415 2.261c-0.858 0.803-2.193 0.803-3.051 0l-2.415-2.261c-0.395-0.37-0.911-0.583-1.452-0.601l-3.306-0.109c-1.175-0.039-2.119-0.982-2.158-2.157l-0.109-3.306c-0.018-0.541-0.232-1.057-0.601-1.452l-2.261-2.415c-0.803-0.858-0.803-2.193 0-3.051l2.261-2.415c0.37-0.395 0.583-0.911 0.601-1.452l0.109-3.306c0.039-1.175 0.982-2.119 2.158-2.158l3.306-0.109c0.541-0.018 1.057-0.232 1.452-0.601l2.415-2.261z"
        />
        <path
          fill="none"
          stroke="#565656"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeMiterlimit="4"
          strokeWidth="2.9767"
          d="M12.26 16.372l2.837 2.977 6.093-5.954"
        />
      </symbol>
      <symbol id="icon-shipping" viewBox="0 0 28 32">
        <path
          fill="none"
          stroke="#565656"
          strokeLinejoin="miter"
          strokeLinecap="butt"
          strokeMiterlimit="4"
          strokeWidth="2.9091"
          d="M1.686 22.481l12.486 7.338 12.356-7.272"
        />
        <path
          fill="#fff"
          stroke="#565656"
          strokeLinejoin="miter"
          strokeLinecap="butt"
          strokeMiterlimit="4"
          strokeWidth="2.9091"
          d="M2.865 7.977l11.299-6.523 11.299 6.523-11.299 6.523-11.299-6.523z"
        />
        <path
          fill="none"
          stroke="#565656"
          strokeLinejoin="miter"
          strokeLinecap="butt"
          strokeMiterlimit="4"
          strokeWidth="2.9091"
          d="M1.8 8v15.273"
        />
        <path
          fill="none"
          stroke="#565656"
          strokeLinejoin="miter"
          strokeLinecap="butt"
          strokeMiterlimit="4"
          strokeWidth="2.9091"
          d="M14.213 14.813v13.551"
        />
        <path
          fill="none"
          stroke="#565656"
          strokeLinejoin="miter"
          strokeLinecap="butt"
          strokeMiterlimit="4"
          strokeWidth="2.9091"
          d="M26.527 8v15.273"
        />
      </symbol>
      <symbol id="icon-money-back-guarantee" viewBox="0 0 32 32">
        <path
          fill="#565656"
          d="M4.788 24.651c0.62-0.599 1.608-0.581 2.207 0.039 2.273 2.355 5.457 3.816 8.986 3.816 6.897 0 12.488-5.591 12.488-12.488s-5.591-12.488-12.488-12.488c-3.528 0-6.713 1.461-8.986 3.816-0.599 0.62-1.587 0.638-2.207 0.039s-0.638-1.587-0.039-2.207c2.837-2.939 6.822-4.77 11.232-4.77 8.621 0 15.61 6.989 15.61 15.61s-6.989 15.61-15.61 15.61c-4.41 0-8.395-1.831-11.232-4.769-0.599-0.62-0.581-1.608 0.039-2.207z"
        />
        <path
          fill="#565656"
          d="M5.463 0c0.862 0 1.561 0.699 1.561 1.561v3.902h3.902c0.862 0 1.561 0.699 1.561 1.561s-0.699 1.561-1.561 1.561h-5.463c-0.862 0-1.561-0.699-1.561-1.561v-5.463c0-0.862 0.699-1.561 1.561-1.561z"
        />
        <path
          fill="#565656"
          d="M12.213 21.398c-0.524-0.341-0.562-0.861-0.187-1.363l0.562-0.789c0.393-0.538 0.862-0.574 1.442-0.233 0.599 0.359 1.461 0.735 2.36 0.735 0.674 0 1.405-0.251 1.405-1.022 0-1.435-6.088-1.56-6.088-5.416 0-2.008 1.499-3.443 3.578-3.82v-0.699c0-0.646 0.3-0.986 0.974-0.986h0.112c0.656 0 0.955 0.287 0.955 0.807v0.825c1.218 0.126 2.117 0.52 2.679 0.861 0.543 0.305 0.599 0.807 0.318 1.345l-0.45 0.843c-0.318 0.592-0.787 0.663-1.405 0.341-0.524-0.287-1.274-0.556-2.061-0.556-0.674 0-1.424 0.287-1.424 1.004 0 1.524 6.088 1.417 6.088 5.38 0 1.901-1.349 3.551-3.746 3.909v0.646c0 0.646-0.3 0.986-0.955 0.986h-0.112c-0.674 0-0.974-0.287-0.974-0.807v-0.825c-1.405-0.197-2.435-0.735-3.072-1.166z"
        />
        <path
          fill="#565656"
          d="M4.683 22.634c0 0.862-0.699 1.561-1.561 1.561s-1.561-0.699-1.561-1.561c0-0.862 0.699-1.561 1.561-1.561s1.561 0.699 1.561 1.561z"
        />
        <path
          fill="#565656"
          d="M3.122 17.171c0 0.862-0.699 1.561-1.561 1.561s-1.561-0.699-1.561-1.561c0-0.862 0.699-1.561 1.561-1.561s1.561 0.699 1.561 1.561z"
        />
        <path
          fill="#565656"
          d="M3.902 11.707c0 0.862-0.699 1.561-1.561 1.561s-1.561-0.699-1.561-1.561c0-0.862 0.699-1.561 1.561-1.561s1.561 0.699 1.561 1.561z"
        />
      </symbol>
      <symbol id="icon-loop" viewBox="0 0 30 32">
        <path d="M11.084 21.693c0.021-0.211-0.002-0.424-0.069-0.625s-0.174-0.386-0.317-0.543-0.316-0.282-0.51-0.367c-0.194-0.085-0.404-0.129-0.616-0.128h-6.046c-0.935 0-1.832 0.372-2.494 1.033s-1.033 1.558-1.033 2.494v6.046c-0.001 0.212 0.042 0.421 0.128 0.615s0.21 0.368 0.367 0.51c0.157 0.143 0.342 0.25 0.543 0.317s0.414 0.090 0.625 0.069c0.384-0.052 0.735-0.244 0.986-0.538s0.384-0.672 0.375-1.059v-4.706l0.892 1.063c1.807 2.157 4.198 3.747 6.886 4.578s5.559 0.868 8.268 0.107c2.709-0.761 5.14-2.289 7.002-4.399s3.075-4.713 3.493-7.495c0.029-0.206 0.013-0.415-0.046-0.614s-0.16-0.383-0.297-0.54c-0.131-0.157-0.295-0.284-0.481-0.371s-0.388-0.132-0.593-0.133h-0.171c-0.348 0.004-0.683 0.134-0.943 0.366s-0.428 0.549-0.473 0.894c-0.342 2.207-1.314 4.268-2.8 5.935s-3.421 2.87-5.575 3.463c-2.153 0.593-4.431 0.551-6.561-0.12s-4.020-1.944-5.444-3.665l-0.68-0.826h3.985c0.387 0.010 0.764-0.124 1.059-0.375s0.486-0.602 0.538-0.986z"></path>
        <path d="M30.231 2.397c0.001-0.212-0.043-0.422-0.128-0.616s-0.21-0.368-0.367-0.51-0.342-0.25-0.543-0.317c-0.201-0.066-0.414-0.090-0.625-0.069-0.384 0.052-0.735 0.244-0.986 0.538s-0.385 0.672-0.375 1.059v4.706l-0.892-1.063c-1.807-2.157-4.198-3.747-6.886-4.578s-5.56-0.868-8.268-0.107-5.14 2.289-7.002 4.399c-1.862 2.11-3.075 4.713-3.493 7.495-0.029 0.206-0.013 0.415 0.046 0.614s0.16 0.383 0.297 0.54c0.131 0.157 0.295 0.284 0.481 0.371s0.388 0.132 0.593 0.133h0.171c0.348-0.004 0.683-0.134 0.943-0.366s0.428-0.549 0.473-0.894c0.342-2.207 1.314-4.268 2.8-5.935s3.422-2.87 5.575-3.463c2.153-0.593 4.432-0.551 6.561 0.12s4.020 1.944 5.444 3.665l0.68 0.826h-3.985c-0.387-0.010-0.764 0.124-1.059 0.375s-0.486 0.602-0.538 0.986c-0.021 0.211 0.002 0.424 0.069 0.625s0.174 0.386 0.317 0.543 0.316 0.282 0.51 0.367c0.194 0.085 0.404 0.129 0.616 0.128h6.046c0.935 0 1.832-0.372 2.494-1.033s1.033-1.558 1.033-2.494v-6.046z"></path>
      </symbol>
    </defs>
  </svg>
);

export default Icons;
